<template>
  <div>
    <ClientOnly>
      <slot></slot>
      <template #fallback>
        <slot name="placeholder"></slot>
      </template>
    </ClientOnly>
  </div>
</template>
