<template>
  <div class="s-shop">
    <div class="s-shop-right-block h-full">
      <Swiper
        v-bind="swiperSettings"
        class="s-shop-hooper"
      >
        <SwiperSlide
          v-for="(slide, indx) in shopData"
          :key="indx"
          :index="indx"
        >
          <div>
            <NuxtLink
              :to="slide.featured_slider_slide_link"
              class="cursor-pointer"
            >
              <NuxtImg
                :src="$correctedImageUrl(slide.featured_slider_slide_img).cleanUrl"
                alt="Slider image"
                class="s-shop-right-block-img"
                loading="lazy"
                width="680"
                height="680"
                :srcset="$correctedImageUrl(slide.featured_slider_slide_img, { general: '640' }).srcSet"
              />
            </NuxtLink>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script>
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { Autoplay } from 'swiper'
  import 'swiper/css'
  export default {
    components: {
      Swiper,
      SwiperSlide
    },
    props: {
      shopData: {
        type: Array,
        default: () => [
          {
            img: '',
            name: 'Flower (3.5g)',
            sort: 'hybrid',
            thc: 23.24,
            cbd: 0.87,
            price: 105,
            discountPrice: 95
          },
          {
            img: '',
            name: 'Flower (3.5g)',
            sort: 'sativa',
            thc: 23.24,
            cbd: 0.87,
            price: 105,
            discountPrice: 95
          },
          {
            img: '',
            name: 'Flower (3.5g)',
            sort: '123',
            thc: 23.24,
            cbd: 0.87,
            price: 105,
            discountPrice: 95
          }
        ]
      }
    },

    data() {
      return {
        swiperSettings: {
          autoplay: { delay: 4000 },
          modules: [Autoplay]
        }
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-shop-hooper {
    height: 100% !important;
  }
</style>
