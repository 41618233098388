<template>
  <div class="s-learn-more py-24">
    <div
      v-for="(item, index) in content"
      :key="item.id"
      class="s-learn-more-section relative"
    >
      <div
        v-if="index === 0"
        class="flex flex-col justify-center items-center absolute h-1/2 md:h-full w-1/2 cursor-pointer"
        @click="showModal = true"
      >
        <img
          style="height: 100px; width: 100px"
          src="/images/play.svg?url"
          alt="Play video"
        />
      </div>
      <SalveImage
        v-if="index === 0"
        :url="item.content_block_image"
        class="block-image h-full w-full"
        loading="lazy"
      />
      <div
        v-if="index === 0"
        class="s-learn-more-section-content text-center md:text-left flex items-start lg:pr-36 lg:pl-24"
      >
        <div class="header text-center md:text-left">
          <SalveImage
            :url="item.content_block_title_image.url"
            class="pharma w-64 md:max-w-xs md:w-full"
            loading="lazy"
          />
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="content py-5 px-5 md:px-0">
          <div v-html="item.content_block_content"></div>
        </div>
        <NuxtLink
          :to="{ path: item.content_block_post_id.post_name }"
          class="btn"
          ><span>{{ item.content_block_post_id.post_title }}</span></NuxtLink
        >

        <ModalVideo
          :show="showModal"
          @close="closePopup"
        ></ModalVideo>
      </div>

      <div
        v-if="index === 1"
        class="s-learn-more-section-content text-center left md:text-right flex lg:pr-24 lg:pl-36"
      >
        <div class="header text-center md:text-right">
          <SalveImage
            :url="item.content_block_title_image.url"
            class="medicine w-64 md:max-w-xs md:w-full"
            loading="lazy"
          />
        </div>
        <div class="content py-5 px-5 md:px-0">
          <div v-html="item.content_block_content"></div>
        </div>
        <NuxtLink
          :to="{ path: item.content_block_post_id.post_name }"
          class="btn"
          ><span>{{ item.content_block_post_id.post_title }}</span></NuxtLink
        >
      </div>
      <SalveImage
        v-if="index === 1"
        :url="item.content_block_image"
        class="block-image w-full h-full"
        loading="lazy"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      content: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data: () => ({ showModal: false }),
    methods: {
      closePopup() {
        this.showModal = false
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-learn-more {
    max-width: 1441px;
    margin: 0 auto;
    font-family: DM Mono, serif;

    @media screen and (max-width: 720px) {
      padding: 0;
    }

    &-section {
      display: flex;

      @media screen and (max-width: 720px) {
        flex-direction: column;
        align-items: center;
        margin-bottom: 36px;

        &:last-child {
          flex-direction: column-reverse;
        }
      }

      .block-image {
        width: 50%;

        @media screen and (max-width: 720px) {
          width: 100%;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #7c93a5;
        font-weight: 500;
        width: 50%;
        &.left {
          align-items: flex-end;

          @media screen and (max-width: 720px) {
            align-items: center;
          }
        }

        @media screen and (max-width: 720px) {
          padding-left: 52px;
        }

        @media screen and (max-width: 720px) {
          padding-left: 0;
          width: 100%;
          align-items: center;
        }

        .header {
          img {
          }
        }

        .content {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 16px;

          @media screen and (max-width: 720px) {
            font-size: 14px;
            line-height: 24px;
          }

          @media screen and (max-width: 720px) {
            line-height: 20px;
            font-size: 14px;
          }
        }

        .btn {
          font-size: 16px;
          line-height: 20px;
          position: relative;
          @apply text-white bg-blue py-3 px-5 text-sm;

          &::after,
          &::before {
            content: '';
            width: 0;
            height: 2px;
            position: absolute;
            transition: all 0.2s linear;
            background: #faa5c9;
          }

          &::after {
            bottom: 0;
            right: 0;
          }

          &::before {
            top: 0;
            left: 0;
          }

          &:hover {
            &::after,
            &::before {
              width: 100%;
              box-shadow: 0 0 2px #ffd8eb;
            }
            span {
              display: block;
              &::before,
              &::after {
                width: 2px;
                height: 100%;
                box-shadow: 0 0 2px #ffd8eb;
              }
            }
          }

          @media screen and (max-width: 720px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
</style>
