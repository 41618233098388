<template>
  <div class="s-faq font-alt flex flex-col w-full lg:w-5/12 my-16 md:my-20 px-6 lg:px-20">
    <div class="text-blue text-2xl md:text-3xl font-semibold mb-2.5 md:mb-3 text-left">What the FAQ!?</div>

    <div class="flex flex-col flex-nowrap md:flex-wrap md:items-state md:items-center text-left">
      <div
        v-for="(item, index) in faqData"
        :key="index"
        class="s-faq-content border-b border-blue-light p-2.5 md:p-4.5 w-full"
      >
        <div class="flex justify-between">
          <div
            class="font-alt font-medium text-sm md:text-base text-blue cursor-pointer pr-5"
            @click="toggleDescription(index)"
          >
            {{ item.faq.post_title }}
          </div>
          <button
            class="text-lg"
            @click="toggleDescription(index)"
          >
            {{ item.status ? '&minus;' : '&plus;' }}
          </button>
        </div>

        <div
          class="font-alt font-medium text-xs md:text-sm mt-2 md:mt-3.5 text-gray-med leading-5"
          :class="{ closed: !item.status }"
        >
          {{ item.faq.post_content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * Adds a 'show' key to each FAQ element and sets show to false
   * @param data an array of FAQ objects
   * @return Array
   */
  const ProcessFAQData = (data) => {
    return data.map((item) => {
      item.status = false
      return item
    })
  }

  export default {
    props: {
      /** An array of FAQ objects */
      faq: {
        type: Array,
        default: () => {
          return []
        }
      }
    },

    data() {
      return {
        faqData: ProcessFAQData(this.faq),
        currentIndex: null
      }
    },

    methods: {
      toggleDescription(index) {
        this.faqData = ProcessFAQData(this.faq)

        if (index !== this.currentIndex) {
          this.faqData[index].status = true
          this.currentIndex = index
          return
        }

        this.currentIndex = null
      }
    }
  }
</script>

<style scoped lang="postcss">
  .closed {
    display: none;
  }

  .s-faq {
    @media screen and (max-width: 772px) {
      height: auto;
    }

    @media screen and (max-width: 640px) {
      height: auto;
      padding-bottom: inherit;
      padding-top: inherit;
      text-align: center;
    }
  }
</style>
