<template>
  <RenderlessXUser
    v-slot="{ xGetAddressList, xSelectNewLocation, xSelectedAddress }"
    @address="addressChanged"
  >
    <slot
      :locations="xGetAddressList"
      :change-location="changeLocation(xSelectNewLocation)"
      :selected="xSelectedAddress"
      :x-find-next-delivery-date="xFindNextDeliveryDate"
    />
  </RenderlessXUser>
</template>
<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'XSelectLocation',

    computed: {
      ...mapGetters('regions', ['xFindNextDeliveryDate'])
    },

    methods: {
      /**
       * Returns a function that can be used to change the selected address for the currently logged in user
       * @param xSelectNewLocation the function to call to change the address
       */
      changeLocation(xSelectNewLocation) {
        return (addressID) => {
          xSelectNewLocation(addressID)
          this.$emit('change', addressID)
        }
      },

      /** Called when the selected address changes. Emits a changed event that can be caught by parent old__components */
      addressChanged(address) {
        this.$emit('changed', address?.address_id)
      }
    }
  }
</script>
