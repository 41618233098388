<script setup>
  import { computed } from 'vue'
  import { useMediaQuery } from '@vueuse/core'

  const props = defineProps({
    sources: {
      type: Array,
      required: true
    },
    posters: {
      type: Array,
      required: false,
      default: () => []
    }
  })

  const sourceQueries = {}
  props.sources.forEach((source) => {
    sourceQueries[source.src] = source.media ? useMediaQuery(source.media) : null
  })

  const activeSources = computed(() => {
    return props.sources.filter((source) => sourceQueries[source.src].value)
  })

  const posterQueries = {}
  props.posters.forEach((poster) => {
    posterQueries[poster.src] = poster.media ? useMediaQuery(poster.media) : null
  })

  const activePoster = computed(() => {
    const poster = props.posters.find((p) => posterQueries[p.src].value)
    return poster?.src
  })
</script>

<template>
  <video :poster="activePoster">
    <source
      v-for="source in activeSources"
      :key="source.src"
      :src="source.src"
      :type="source.type"
    />
  </video>
</template>
