<template>
  <section>
    <ModalPopup
      :show="show"
      class="s-delivery-modal"
      @close="$emit('close')"
    >
      <template #modal-content>
        <div class="flex text-center flex-col rounded">
          <div class="video-player">
            <video
              autoplay
              controls
            >
              <source
                src="https://theflowery.s3.amazonaws.com/videos/intro-video.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </template>
    </ModalPopup>
  </section>
</template>

<script>
  export default {
    name: 'VideoPopup',

    props: {
      show: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {}
    }
  }
</script>

<style lang="postcss" scoped>
  .video-player {
    width: 800px;
    @media screen and (max-width: 768px) {
      width: 400px;
    }
  }
</style>

<style lang="postcss" scoped>
  .video-player {
    width: 800px;
    @media screen and (max-width: 768px) {
      width: 400px;
    }
  }
</style>
