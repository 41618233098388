<template>
  <div
    class="s-strain-drops w-full lg:w-7/12"
    :style="backgroundStyles"
  >
    <div class="s-strain-drops-container">
      <div class="title">{{ title }}</div>
      <div
        class="description"
        v-html="content"
      ></div>
      <!-- <button class="btn">See more <span>&gt;</span></button> -->
      <div
        v-for="(item, indx) in products"
        :key="indx"
        class="table flex flex-row w-full lg:grid lg:gap-1 lg:grid-cols-3"
      >
        <div class="col-span-2">
          <div
            v-if="item.strain_drops_product_status === 'Processing'"
            class="circle mr-4 yellow"
          >
            <div class="arc arc_start"></div>
            <span>{{ item.strain_drops_product_completed }}%</span>
            <div class="arc arc_end"></div>
          </div>

          <div
            v-else-if="item.strain_drops_product_status === 'Cutting'"
            class="circle mr-4 pink"
          >
            <div class="arc arc_start"></div>
            <span>{{ item.strain_drops_product_completed }}%</span>
            <div class="arc arc_end"></div>
          </div>

          <div
            v-else-if="item.strain_drops_product_status === 'Growing'"
            class="circle mr-4 pink"
          >
            <div class="arc arc_start"></div>
            <span>{{ item.strain_drops_product_completed }}%</span>
            <div class="arc arc_end"></div>
          </div>

          <div
            v-else
            class="circle mr-4 yellow"
          >
            <div class="arc arc_start"></div>
            <span>{{ item.strain_drops_product_completed }}%</span>
            <div class="arc arc_end"></div>
          </div>

          <div class="flex flex-col">
            <div class="product">{{ item.strain_drops_product_name }}</div>
            <div class="sort">{{ item.strain_drops_product_lineage }}</div>
          </div>
        </div>

        <div class="justify-end mt-5 col-span-1">
          <div
            v-if="item.strain_drops_product_status === 'Processing'"
            class="stage yellow"
          >
            <span>
              {{ item.strain_drops_product_status }}
            </span>
          </div>
          <div
            v-else-if="item.strain_drops_product_status === 'Cutting'"
            class="stage pink"
          >
            <span>
              {{ item.strain_drops_product_status }}
            </span>
          </div>
          <div
            v-else-if="item.strain_drops_product_status === 'Growing'"
            class="stage pink"
          >
            <span>
              {{ item.strain_drops_product_status }}
            </span>
          </div>
          <div
            v-else
            class="stage yellow"
          >
            <span>
              {{ item.strain_drops_product_status }}
            </span>
          </div>

          <div
            v-if="item.strain_drops_product_drop_date"
            class="data"
          >
            <span>
              {{ item.strain_drops_product_drop_date }}
            </span>
          </div>
        </div>
        <!-- <button class="table-btn"><span>&gt;</span></button> -->
      </div>
    </div>
  </div>
</template>
<script>
  import { imageData } from '@/utils/images'

  export default {
    props: {
      title: {
        type: String,
        default: 'Title'
      },
      content: {
        type: String,
        default: 'strain drop'
      },
      products: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data: () => ({
      strainDrops: [
        {
          willingness: 75,
          title: 'MK Ultra',
          sort: 'Hybrid',
          stage: 'processing',
          data: '08/2021',
          color: '#FFFBD1'
        },
        {
          willingness: 60,
          title: 'MK Ultra',
          sort: 'Hybrid',
          stage: 'cutting',
          data: '08/2021',
          color: '#F6D7E4'
        },
        {
          willingness: 50,
          title: 'MK Ultra',
          sort: 'Sativa',
          stage: 'growing',
          data: '09/2021',
          color: '#F6D7E4'
        },
        {
          willingness: 45,
          title: 'MK Ultra',
          sort: 'Indica',
          stage: 'growing',
          data: '10/2021',
          color: '#F6D7E4'
        }
      ]
    }),
    computed: {
      backgroundStyles() {
        const { path, provider } = imageData('/midnight.jpg')
        return {
          backgroundImage: `url('${this.$img(path, { quality: 50 }, { provider })}')`
        }
      }
    }
  }
</script>

<style scoped lang="postcss">
  .s-strain-drops {
    font-family: DM Mono, serif;
    margin: 0;

    @media screen and (max-width: 640px) {
      position: relative;

      top: 0;
      @apply py-16 pb-0 md:pb-16 w-full;
    }

    .s-strain-drops-container {
      position: relative;
      @apply m-0 mb-8 md:m-20;

      @media screen and (max-width: 772px) {
        margin-top: 52px;
        margin-left: 86px;
      }

      @media screen and (max-width: 640px) {
        margin-top: inherit;
        margin-left: inherit;
        margin-right: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .title {
        font-weight: 500;
        font-size: 30px;
        line-height: 39px;
        color: #002d52;
        margin-bottom: 20px;

        @media screen and (max-width: 772px) {
          font-size: 24px;
          line-height: 26px;
          margin-bottom: 13px;
        }

        @media screen and (max-width: 640px) {
          font-size: 24px;
          line-height: 26px;
          text-align: center;
          width: inherit;
        }
      }

      .description {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #7c93a5;
        margin-bottom: 20px;

        @media screen and (max-width: 772px) {
          font-size: 14px;
          line-height: 24px;
          margin-bottom: 26px;
        }

        @media screen and (max-width: 640px) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 21px;
          text-align: center;
          @apply px-10;
        }
      }

      .btn {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        text-align: center;
        text-decoration-line: underline;
        color: #4899b9;
        position: absolute;
        top: 7px;
        right: 10px;

        @media screen and (max-width: 772px) {
          font-size: 14px;
          line-height: 18px;
          top: 4px;
          right: 7px;
        }

        @media screen and (max-width: 640px) {
          position: relative;
          top: inherit;
          right: inherit;
          @apply mb-5;
        }
      }

      .table {
        align-items: center;
        padding: 20px 0;
        border-bottom: 2px solid rgba(255, 255, 255, 0.7);
        &:last-child {
          border-bottom: none;
        }

        @media screen and (max-width: 772px) {
          padding: 9px;
        }

        @media screen and (max-width: 640px) {
          display: flex;
          justify-content: center;
          flex-direction: column;

          @apply px-10 w-full items-center text-center;
        }

        > div {
          display: flex;
          flex-direction: row;
          @media screen and (max-width: 640px) {
            display: flex;
            justify-content: center;
            flex-direction: row;
            text-align: center;
            @apply w-full mb-3 mt-3;
          }
        }

        .circle {
          position: relative;
          border-radius: 50%;
          background: white;
          width: 50px;
          height: 50px;
          z-index: 2;

          .arc {
            position: absolute;
            top: -1px;
            left: -1px;
            width: 55px;
            height: 55px;
            border-radius: 100%;
            border: 3px solid;
          }

          &.yellow {
            .arc_start {
              border-color: #fffbd1 transparent transparent #fffbd1;
              -webkit-transform: rotate(90deg);
              -moz-transform: rotate(90deg);
              -ms-transform: rotate(90deg);
              -o-transform: rotate(90deg);
              transform: rotate(90deg);
            }

            .arc_end {
              border-color: transparent;
              -webkit-transform: rotate(75deg);
              -moz-transform: rotate(75deg);
              -ms-transform: rotate(75deg);
              -o-transform: rotate(75deg);
              transform: rotate(75deg);
            }
          }
          &.red {
            .arc_start {
              border-color: transparent #f6d7e4 #f6d7e4 #f6d7e4;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            .arc_end {
              border-color: #f6d7e4 transparent #f6d7e4 #f6d7e4;
              -webkit-transform: rotate(75deg);
              -moz-transform: rotate(75deg);
              -ms-transform: rotate(75deg);
              -o-transform: rotate(75deg);
              transform: rotate(75deg);
            }
          }
          &.pink {
            .arc_start {
              border-color: transparent #f3b6d0 #f3b6d0 #f3b6d0;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            .arc_end {
              border-color: transparent;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }
          &.green {
            .arc_start {
              border-color: #f3b6d0 transparent #f3b6d0 #f3b6d0;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            .arc_end {
              border-color: transparent;
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -ms-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }
          }

          @media screen and (max-width: 1200px) {
            display: none;
          }

          span {
            background: #fff;
            height: 52px;
            width: 52px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            @apply text-sm mr-3;
          }
        }

        .product {
          @apply font-main;
          font-weight: 700;
          text-transform: uppercase;
          @apply text-blue text-lg whitespace-nowrap lg:mr-5;
        }

        .sort {
          line-height: 18px;
          color: #002d52;
        }

        .data {
          span {
            font-weight: 500;
            text-align: center;
            color: #002d52;
            background: #cbe8f7;
            @apply rounded-full px-4 py-2 text-sm;
          }
        }

        .stage {
          font-weight: 500;
          text-align: center;
          color: #002d52;

          &.yellow {
            span {
              background-color: #fffbd1;
              @apply rounded-full px-4 py-2 text-sm text-center mr-3 font-bold;
            }
          }
          &.red {
            span {
              background-color: #f6d7e4;
              @apply rounded-full px-4 py-2 text-sm text-center mr-3 font-bold;
            }
          }
          &.green {
            span {
              background-color: #d7f6dd;
              @apply rounded-full px-4 py-2 text-sm text-center mr-3 font-bold;
            }
          }
          &.pink {
            span {
              background-color: #f3b6d0;
              @apply rounded-full px-4 py-2 text-sm text-center mr-3 font-bold;
            }
          }

          @media screen and (max-width: 772px) {
            border-radius: 14px;
            font-size: 12px;
            text-transform: lowercase;
            line-height: 16px;
          }
        }

        .table-btn {
          width: 45px;
          height: 45px;
          border-radius: 50%;
          border: 2px solid #fff;
          box-sizing: border-box;
          transform: matrix(1, 0, 0, -1, 0, 0);

          @media screen and (max-width: 772px) {
            width: 24px;
            height: 24px;
            border: 1px solid #e0e5e8;
          }
        }
      }
    }
  }
</style>
